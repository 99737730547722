<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <!-- <el-form-item label="模板名称:">
                        <el-input v-model='filters.name' placeholder="输入模板名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item> -->
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
        :data="listData"
        highlight-current-row
        v-loading="listLoading"
        border=""
        @current-change="selectCurrentRow"
        style="width: 95%;"
        >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="Name" align="center" label="通知模板名称" width="300"></el-table-column>
            <el-table-column prop="SendTime" align="center" :formatter="formatCreateTime" label="发送通知时间" width=""></el-table-column>
            <el-table-column prop="NoticeCount" align="center" label="通知数量" width=""></el-table-column>
            <el-table-column prop="Days" align="center" label="已发送天数" width=""></el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="200">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="primary" size="small">获取最新发送名单</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 发送通知 -->
        <el-dialog
        title="发送通知"
        :visible.sync="viewsDetail"
        v-model="viewsDetail"
        :close-on-click-modal="false"
        >
            <div>
                <p style="text-align:center;">需发送{{number}}条单位信息。</p>
                <p style="text-align:center;">是否立即发送通知？</p>
                <!-- <p style="text-align:center;">是否立即发送{{!formData.sendType?'短信':'语音'}}通知？</p> -->
            </div>
            <!-- <el-form :model="formData" label-width="150px" :rules="formDataRules" ref="formData">
                <el-form-item label="类型："  prop="selectType">
                    <el-select v-model="formData.selectType">
                        <el-option
                            v-for="item in questionswayArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择模板："  prop="notificationType">
                    <el-select v-model="formData.notificationType">
                        <el-option
                            v-for="item in notificationArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID">
                        </el-option>
                        <el-pagination
                            layout="prev, pager, next"
                            @current-change="handleCurrentChange"
                            :total="pages.dataCount">
                        </el-pagination>
                    </el-select>
                </el-form-item>
                <el-form-item label="通知方式："  prop="sendType">
                    <el-radio v-for="(item,index) in sendTypeArr" :key="index" v-model="formData.sendType" :label="item.value">{{item.name}}</el-radio>
                </el-form-item>
            </el-form> -->
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="viewsDetail = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">发送</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getNoticeBillList,getToBeNoticeBillCount,getNoticeTemplateListPage,createNoticeBill } from '@/api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return {
            filters: {
                name: '',
            },
            buttonList: [],
            listLoading: false,
            listData: [],
            viewsDetail: false,
            addLoading: false,
            number: '3000',
            questionswayArr: [
                {value:0,name:'持证监管'},
                {value:1,name:'考试'},
                {value:2,name:'学习'},
                {value:3,name:'宣传推广'},
                {value:4,name:'其他'},
            ],
            notificationArr: [],
            sendTypeArr: [
                {value:0,name:'短信'},
                {value:1,name:'语音'},
            ],
            formData: {
                selectType: '',
                notificationType: '',
                sendType: 0,
            },
            formDataRules: {
                selectType: [{required: true, message: "请选择类型", trigger: "blur" }],
                notificationType: [{required: true, message: "请选择通知模板", trigger: "blur" }],
                sendType: [{required: true, message: "请选择通知方式", trigger: "blur" }],
            },
            //关于分页的obj
            pages: {
                pageSize: 10,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            sendId: '',
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取分页列表
        getListData() {
            var _this = this
            this.listLoading = true;
            getNoticeBillList().then((res) => {
                var result = res.data
                if(result.Success){
                    _this.listLoading = false;
                    this.listData = result.Response
                }
            })
        },
        getNumberFun(params) {
            getToBeNoticeBillCount(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.number = result.Response
                }
            })
        },
        // 获取通知模板
        getSendData() {
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            getNoticeTemplateListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.notificationArr = result.Response.Data
                }
            })
        },
        // 点击table某一行
        selectCurrentRow(val) {
            
        },
        // 操作
        handleClick(val) {
            if(val){
                var params = {
                    noticeTemplateID: val.NoticeTemplateID
                }
                this.sendId = val.NoticeTemplateID
                this.getNumberFun(params);
                this.getSendData();
                this.viewsDetail = true
            }
        },
        addSubmit() {
                    this.$confirm('确定发送？',"提示",{
                }).then(() => {
                    var params = {
                        NoticeTemplateID: this.sendId
                    }
                    this.addLoading = true;
                    createNoticeBill(params).then((res) => {
                        var result = res.data
                        if(result.Success){
                            this.$message({
                                message: result.Message,
                                type: 'success'
                            });
                            this.getListData();
                            this.viewsDetail = false;
                            this.addLoading = false;
                        }else{
                            this.$message({
                                message: result.Message,
                                type: 'error'
                            });
                        }
                    })
                })
                .catch(() => {

                })
            // this.$refs.formData.validate(valid => {
            //     if (valid) {
            //         var params = {
            //             noticeTemplateID: this.formData.notificationType
            //         }

            //     }
            // })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getSendData();
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.SendTime || row.SendTime == ""
            ? ""
            : util.formatDate.format(new Date(row.SendTime), "yyyy-MM-dd hh:mm");
        },
    },
    mounted() {
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .el-select{
        width 360px!important;
    }
</style>